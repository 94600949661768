import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Address from 'components/publicWebsiteV2/common/Address';

import { SPACING } from '@zola/zola-ui/src/styles/emotion';

type ContainerProps = { orientation: string };

export const Container = styled.div`
  width: 100%;
  margin-top: ${SPACING.XL};
  text-align: center;
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    margin-top: 60px;
  }
`;

export const TravelDetails = styled.div<ContainerProps>`
  position: relative;
  overflow-wrap: anywhere;
  margin-bottom: ${SPACING.XL};
  ${({ theme }) => theme.MEDIA_QUERY.EXCLUDE_DESKTOP} {
    flex-direction: column;
  }
  ${({ orientation }) =>
    orientation === 'HORIZONTAL' &&
    `
    display: flex;
  `}
`;

export const Type = styled.p``;

export const Name = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${SPACING.S24};
`;

export const AddressAndPhone = styled.div`
  margin-bottom: ${SPACING.S24};
  &:empty {
    display: none;
  }
`;

export const StyledAddress = styled(Address)`
  display: inline;
  &:empty {
    display: none;
  }
  margin: 0; // Defensive styling for bootstrap styles applied in preview
`;

export const buttonStyles = css`
  display: inline-block;
`;

export const sharedSpacing = css`
  margin-bottom: ${SPACING.S24};
`;
