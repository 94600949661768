// Types
import type { GenericEntityType } from 'actions/types/website/EntityActionTypes';
import type { WCmsTravelView } from '@zola/svc-web-api-ts-client';

// Utils
import _findIndex from 'lodash/findIndex';
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import { formatDateForEditing } from 'components/manage/EditWebsite/EditWebsiteEntityModal/modals/EventForm/eventTimeUtils';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

export type Travel = {
  title: string;
  value: string;
  icon: string;
  fields: {
    [k: string]: { label?: string; placeholder?: string };
  };
};

export const TravelTypes: Travel[] = [
  {
    title: 'Hotel',
    value: 'HOTEL',
    icon: 'hotel',
    fields: {
      name: { label: 'Hotel Name' },
      address: { label: 'Hotel Name or Address' },
      contactNumber: { label: 'Hotel Phone Number' },
      url: { label: 'Hotel Website Address' },
      code: { label: 'Discount Code', placeholder: 'LOVE1' },
      note: {
        placeholder:
          "This is where we'll be having our rehearsal dinner and welcome drinks. And it’s not far from our ceremony, either. To book, don't forget to use our discount code.",
      },
    },
  },
  {
    title: 'House Or Rental',
    value: 'HOME_RENTAL',
    icon: 'home_rental',
    fields: {
      name: { label: 'House Or Rental Name' },
      address: { label: 'House Or Rental Name or Address' },
      contactNumber: { label: 'House Or Rental Phone Number' },
      url: { label: 'House Or Rental Website Address' },
      note: {
        placeholder:
          'We rented this 12-bedroom house on Airbnb for the wedding weekend. It has private beach access, a pool table, a home movie theater, workout room, hot tub, pool, and helipad. If you’d like to stay here, reach out to the Maid of Honor, Alyssa, at alyssa@email.com.',
      },
    },
  },
  {
    title: 'Flight',
    value: 'FLIGHT',
    icon: 'flight',
    fields: {
      name: { label: 'Airport, Airline or Flight Name' },
      url: { label: 'Website Address' },
      code: { label: 'Discount Code' },
      note: {
        placeholder:
          'NYC has tons of airports, but Laguardia is definitely the easiest for domestic flights and is only a short car ride from all hotel and rental suggestions.',
      },
    },
  },
  {
    title: 'Rental Car',
    value: 'CAR_RENTAL',
    icon: 'car_rental',
    fields: {
      name: { label: 'Rental Car Company Name' },
      address: { label: 'Rental Car Company Name or Address' },
      url: { label: 'Rental Car Company Website Address' },
      contactNumber: { label: 'Rental Car Company Phone' },
      note: {
        placeholder:
          'Our friend set us up with a 15% discount for all of our guests. Enter our name in the promo code section or mention it on the phone when you book.',
      },
    },
  },
  {
    title: 'Shuttle',
    value: 'SHUTTLE',
    icon: 'shuttle',
    fields: {
      name: { label: 'Shuttle Name', placeholder: 'Shuttle From The Main Hotel To The Ceremony' },
      note: {
        placeholder:
          'A shuttle will be running every hour from the reception back to the main hotel for those who forget their dancing shoes :)',
      },
    },
  },
  {
    title: 'Train',
    value: 'TRAIN',
    icon: 'train',
    fields: {
      name: { label: 'Train Name' },
      url: { label: 'Train Website Address' },
      code: { label: 'Discount Code' },
      note: {
        placeholder:
          "We've taken Amtrak several times—a great option for those traveling to NYC from DC or Boston areas.",
      },
    },
  },
  {
    title: 'Travel Note',
    value: 'NOTE',
    icon: 'note',
    fields: {
      name: { label: 'Travel Note Name', placeholder: 'Cab Information' },
      url: { label: 'Travel Note Website Address' },
      note: {
        placeholder:
          'While some cabs are accepting credit cards, several of them refuse them, so make sure you have a little bit of cash on hand.',
      },
    },
  },
];

const getTravel = (type: WCmsTravelView['type']) => {
  const idx = _findIndex(TravelTypes, elem => elem.value === type);
  return idx >= 0 ? TravelTypes[idx] : null;
};

export const getTravelType = (type: WCmsTravelView['type']): string => {
  const travel = getTravel(type);
  return travel ? travel.title : '';
};

export const hasTravelField = (type: WCmsTravelView['type'], field: string): boolean => {
  const travel = getTravel(type);
  return !!(travel && travel.fields[field]);
};

export const getTravelFieldLabel = (
  type: WCmsTravelView['type'],
  field: string
): string | undefined => {
  const travel = getTravel(type);
  return travel?.fields[field].label;
};

export const getTravelFieldPlaceholder = (type: WCmsTravelView['type'], field: string): string => {
  const travel = getTravel(type);
  return travel?.fields[field].placeholder || '';
};

export const getInitialDateValues = (travelItem?: GenericEntityType) => {
  if (travelItem && travelItem.id) {
    return formatDateForEditing(travelItem?.start_at as string, travelItem?.end_at as string);
  }
  return {
    date: formatDateUtc(new Date(), 'MM/DD/YYYY'),
    start_at: '18:00:00', // default to 6pm for new events
  };
};
